<template>
  <section :class="`page ${currentDirectory.slug}`">
    <section class="hero has-hero-image is-between-small-and-medium">
      <div id="hero_image" :style="'background-image: url(' + currentDirectory.hero_uri + ')'" />
      <router-link v-if="logoUri" id="logo" to="/" aria-label="Home">
        <img class="logo" :src="logoUri" alt="Directory Hero">
      </router-link>
      <div class="hero-body directory-home">
        <div class="has-text-centered columns is-multiline is-vcentered">
          <div class="column is-6 is-offset-3">
            <h1 class="title is-size-1 has-text-white has-background-grey-darker p-4">
              {{ currentDirectory.name }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <div v-if="currentDirectory.description" id="stat-panel" class="level">
      <div class="level-item">
        <div v-html="currentDirectory.description" />
      </div>
    </div>
    <div v-if="hasPrimaryFilter && featuredCategories.length > 0" class="section">
      <h1 class="title">
        Featured
      </h1>
      <FeaturedPrimaryFilterList />
    </div>
    <div class="section">
      <h1 class="title">
        All Categories
      </h1>
      <PrimaryFilterList />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FeaturedPrimaryFilterList from '@/components/InstantSearch/FeaturedPrimaryFilterList.vue';
import PrimaryFilterList from '@/components/InstantSearch/PrimaryFilterList.vue';

export default {
  computed: {
    ...mapState({
      logoUri: (state) => state.logo_uri,
      instanceName: (state) => state.name,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    hasPrimaryFilter() {
      return (this.featuredCategories?.length ?? 0) > 0;
    },
    featuredCategories() {
      return this.currentDirectory?.primary_filter
        ?.settings?.filter((facet) => facet?.isFeatured) ?? [];
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    FeaturedPrimaryFilterList,
    PrimaryFilterList,
  },
  metaInfo() {
    const { instanceName } = this;
    const directoryName = this.currentDirectory.name;
    const description = this.currentDirectory.meta_description;

    const title = `${directoryName} - ${instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: description,
      },
    ];
    return { title, meta };
  },
};
</script>
