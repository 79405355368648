<template>
  <component :is="componentType" :attribute="currentDirectory.primary_filter.field">
    <ActiveRefinements class="is-hidden" />

    <div slot-scope="{ createURL }" class="columns is-multiline is-centered">
      <div
        v-for="facet in featuredCategories"
        :key="facet.name"
        class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd"
      >
        <a
          class="is-directory-card-container"
          :style="{ backgroundImage: 'url(' + facet.image_uri + ')' }"
          :href="createURL(facet.name).replace('/home', '')"
        >
          <span class="is-unselectable title">
            {{ facet.name }}
          </span>
        </a>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import ActiveRefinements from '@/components/InstantSearch/ActiveRefinements.vue';

export default {
  data: () => ({
    componentMap: {
      string: 'ais-menu',
      array: 'ais-refinement-list',
    },
  }),
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    componentType() {
      const primaryFilterField = this.currentDirectory?.primary_filter.field;
      const facetType = this.currentDirectory.fields
        .find((field) => field.key === primaryFilterField).type;

      return this.componentMap[facetType];
    },
    featuredCategories() {
      return this.currentDirectory?.primary_filter?.settings.filter((facet) => facet?.isFeatured);
    },
  },
  components: {
    ActiveRefinements,
  },
};
</script>
