<template>
  <ais-refinement-list
    v-if="currentDirectory.primary_filter.field"
    class="primary-filter-list"
    :attribute="currentDirectory.primary_filter.field"
    :limit="200"
    :sort-by="sortBy"
    operator="and"
    searchable
    :transform-items="transform"
  >
    <div slot-scope="{ createURL, items }" class="columns is-multiline">
      <a
        v-for="facet in items"
        :key="facet.value"
        class="column is-4 py-2 px-4"
        :href="createURL(facet.value).replace('/home', '')"
      >
        <span class="is-unselectable">
          {{ facet.label }}
        </span>
      </a>
    </div>
  </ais-refinement-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      sortBy: ['name:asc', 'count:desc'],
    };
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    featuredFacets() {
      return this.currentDirectory?.primary_filter?.settings
        ?.filter((facet) => facet?.isFeatured)
        ?.map((facet) => facet.name)
          ?? [];
    },
  },
  methods: {
    transform(items) {
      return items.filter((item) => !this.featuredFacets.includes(item.value));
    },
  },
};
</script>
